import { useEffect } from 'react';

import { useGetAccount } from 'apps-common/hooks/useGetAccount';
import { track } from 'apps-common/utils/analytics';
import { isGen2User } from 'apps-common/utils/helpers';
import { logger } from 'apps-common/utils/logger';

import { useStore } from '../store';

export const useTrackFunnelCompleted = (prepaidMonths: number, isFetching: boolean) => {
  useEffect(() => {
    if (!isFetching) {
      logger.info('Signup Funnel Completed');
      track({ event: 'Signup Funnel Completed', traits: { prepaidMonths } });
    }
  }, [prepaidMonths, isFetching]);

  const { data, error } = useGetAccount();
  const userType = useStore((state) => state.userType);

  useEffect(() => {
    const isGen2 = isGen2User(userType);

    const hasSubscriptions = Boolean(data?.account.subscriptions?.length);
    if (data && !isGen2 && !hasSubscriptions) {
      // Just missing the subscription for some reason...
      logger.warn(`No subscriptions found for account`, {
        hasSubscriptions,
        isGen2,
      });
    }
  }, [data, error, userType]);
};
