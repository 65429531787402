import { useForm } from 'react-hook-form';
import { FormattedDate } from 'react-intl';
import { useNavigate } from 'react-router';

import { PaymentDisclaimer } from 'apps-common/components/PaymentDisclaimer';
import { PlanSelectHelp } from 'apps-common/components/PlanSelectHelp';
import { ProductSelector } from 'apps-common/components/ProductSelector';
import { useGetMembershipOfferings } from 'apps-common/hooks/useGetMembershipOfferings';
import { BillingPeriod } from 'apps-common/types';
import { track } from 'apps-common/utils/analytics';
import { throwError } from 'apps-common/utils/errorHandler';
import { Experiments, useExperiment } from 'apps-common/utils/experiments';
import { logger } from 'apps-common/utils/logger';
import { t } from 'translations';
import { Form, Header, Loader, SubmitButton } from 'ui';
import { MainContainer } from 'ui/styles/containers';

import { useGetPrepaidInfo } from '../hooks/useGetPrepaidInfo';
import { useGetProductRatePlans } from '../hooks/useGetProductRatePlans';
import { routes } from '../routes';
import { useStore } from '../store';
import { getSignupBannerText } from '../utils/helpers';

export const ProductPage = () => {
  const navigate = useNavigate();

  const userAddressForm = useStore((state) => state.userAddressForm!);
  const setRatePlan = useStore((state) => state.setRatePlan);
  const signupInfo = useStore((state) => state.signupInfo);
  const prepaidPlusExperiment = useExperiment(Experiments.PREPAID_PLUS).experiment.get('prepaidPlus');
  const { data: offerings, error: errorOfferings, isFetching: isFetchingOfferings } = useGetMembershipOfferings();
  const {
    ratePlans,
    isFetching: isFetchingProductRatePlans,
    error: errorOnRatePlans,
  } = useGetProductRatePlans(userAddressForm.shippingAddress.country);

  const { formState, handleSubmit, register } = useForm<{
    product: BillingPeriod;
  }>({
    values: {
      product: BillingPeriod.Years, // always show years as first option
    },
  });

  const { billingStartDate, isPrepaid, prepaidMonths, isFetching: isFetchingPrepaidInfo } = useGetPrepaidInfo();

  let TITLE: JSX.Element | undefined;
  let SUBTITLE: JSX.Element | undefined;

  if (prepaidPlusExperiment) {
    TITLE = t('membership_hub_signup_product_page_title');
    SUBTITLE = isPrepaid
      ? t('membership_hub_signup_subtitle_prepaid', {
          prepaid_validto: <FormattedDate value={billingStartDate} format="default" />,
        })
      : t('membership_hub_signup_subtitle');
  } else {
    TITLE = t('membership_hub_ongoing_plan');
    SUBTITLE = isPrepaid
      ? t('membership_hub_choose_ongoing_plan_after_prepaid', {
          months: prepaidMonths,
        })
      : t('membership_hub_signup_product_subtitle_normal_flow');
  }

  const header = (
    <Header
      appType="signup"
      pageType="create"
      title={TITLE}
      subTitle={isFetchingOfferings ? undefined : SUBTITLE}
      steps={prepaidPlusExperiment ? undefined : { current: 2, total: 3 }}
      testId="membership-plan-header"
      bannerText={prepaidPlusExperiment ? undefined : getSignupBannerText(isPrepaid, prepaidMonths)}
      onBackClick={() => navigate(routes.address)}
      ringSerialStatus={signupInfo.ringSerialStatus}
    />
  );

  const isFetching = isFetchingOfferings || isFetchingProductRatePlans || isFetchingPrepaidInfo;
  if (isFetching) {
    return (
      <>
        {header}
        <MainContainer>
          <Loader />
        </MainContainer>
      </>
    );
  }

  if (errorOnRatePlans || !ratePlans) {
    throwError('noProductFound', errorOnRatePlans ?? 'No products data');
  }

  if (errorOfferings || !offerings) {
    throwError('errorOnGettingOfferings', errorOfferings ?? 'Offerings not found');
  }

  const onSubmit = handleSubmit((data) => {
    const { product: billingPeriod } = data;
    const ratePlan = ratePlans.find((p) => p.billingPeriod === billingPeriod)!;

    setRatePlan(ratePlan);
    track({
      event: 'Membership Plan Selected',
      payload: {
        productType: ratePlan.billingPeriod,
      },
    });

    logger.info('Plan selected, continuing to payment method page');
    navigate(routes.paymentMethod);
  });

  return (
    <>
      {header}
      <MainContainer>
        <Form onSubmit={onSubmit}>
          <ProductSelector
            ratePlans={ratePlans}
            isPrepaid
            prepaidMonths={prepaidMonths}
            showPrepaidInfo={prepaidPlusExperiment as boolean}
            {...register('product', { required: true })}
          />
          <SubmitButton disabled={!formState.isValid} data-testid="membership-plan-next-button">
            {t('membership_signup_button_next')}
          </SubmitButton>
          {PlanSelectHelp(prepaidMonths)}
          <PaymentDisclaimer />
        </Form>
      </MainContainer>
    </>
  );
};
