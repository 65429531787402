import { useEffect } from 'react';

import { useCreateEntitlementSubscription } from 'apps-common/hooks/useCreateEntitlementSubscription';
import { useCreatePartnershipSubscription } from 'apps-common/hooks/useCreatePartnershipSubscription';
import { useGetMembershipOfferings } from 'apps-common/hooks/useGetMembershipOfferings';
import { track } from 'apps-common/utils/analytics';
import { isEntitlementUser, isGen2User } from 'apps-common/utils/helpers';

export const useCreateSubscription = (userCreated: boolean) => {
  const { data: membershipOfferings } = useGetMembershipOfferings(false);
  const membershipType = membershipOfferings?.membershipStatus.type;
  const subscriptionFound = membershipOfferings?.membershipStatus.subscriptionFound;
  const eligibleForPartnership = membershipOfferings?.eligibleForPartnership;

  const {
    mutate: createEntitlementSubscription,
    isSuccess: entitlementSubscriptionIsSuccess,
    error: entitlementSubscriptionError,
  } = useCreateEntitlementSubscription();

  const {
    mutate: createPartnershipSubscription,
    isSuccess: partnershipSubscriptionIsSuccess,
    error: partnershipSubscriptionError,
  } = useCreatePartnershipSubscription();

  // Create subscription for entitlement users
  useEffect(() => {
    if (userCreated && membershipType && isEntitlementUser(membershipType) && !subscriptionFound) {
      createEntitlementSubscription();
    }
  }, [createEntitlementSubscription, membershipType, subscriptionFound, userCreated]);

  // Create subscription for partnership users
  useEffect(() => {
    if (userCreated && eligibleForPartnership && !subscriptionFound && membershipType && !isGen2User(membershipType)) {
      createPartnershipSubscription();
    }
  }, [createPartnershipSubscription, eligibleForPartnership, membershipType, subscriptionFound, userCreated]);

  // Track subscription creation
  useEffect(() => {
    if (entitlementSubscriptionIsSuccess) {
      track({ event: 'Entitlement Subscription Created' });
    }
    if (partnershipSubscriptionIsSuccess) {
      track({ event: 'Partnership Subscription Created' });
    }
  }, [entitlementSubscriptionIsSuccess, partnershipSubscriptionIsSuccess]);

  if (entitlementSubscriptionError) {
    throw entitlementSubscriptionError;
  }
  if (partnershipSubscriptionError) {
    throw partnershipSubscriptionError;
  }
};
