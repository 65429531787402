import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { keyframes, styled } from 'styled-components';

import { useGetMembershipOfferings } from 'apps-common/hooks/useGetMembershipOfferings';
import { throwError } from 'apps-common/utils/errorHandler';
import { t } from 'translations';
import { Button } from 'ui';
import { OuraLogo } from 'ui/components/Icons/Logo';
import { MainContainer } from 'ui/styles/containers';
import { HeaderText } from 'ui/styles/text';

import { routes } from '../routes';

const ContinueButton = styled(Button)`
  width: 100%;
`;

const logoAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(9em);
  }
  60% {
    opacity: 1;
    transform: translateY(9em)
  }
  100% {
    opacity: 1;
    transform: translateY(0)
  }
`;

const logoAnimationWithoutTranslate = keyframes`
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
`;

const animationTiming = {
  logo: 1.25,
  heading: 1,
  subheading: 2,
};

const LogoWrapper = styled.div`
  animation: ${logoAnimation} ${animationTiming.logo}s forwards ease-in-out;

  @media (prefers-reduced-motion: reduce) {
    animation: ${logoAnimationWithoutTranslate} ${animationTiming.logo}s forwards ease-in-out;
  }
`;

// Animations
// 1. Everything is hidden
// 2. Logo appears in the middle of the screen
// 3. First paragraph appears below the logo
// 4. Second paragraph appears below the first paragraph
// 5. Button appears below the second paragraph

const StyledMainContainer = styled(MainContainer)`
  justify-content: center;
  flex: auto;
  color: red;

  & [data-animate] {
    opacity: 0;
    visibility: hidden;
    animation-name: ${fadeIn};
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }

  & [data-animate='heading'] {
    animation-delay: ${animationTiming.logo}s;
  }

  & [data-animate='subheading'] {
    animation-delay: ${animationTiming.logo + animationTiming.heading}s;
  }

  & [data-animate='button'] {
    animation-delay: ${animationTiming.logo + animationTiming.heading + animationTiming.subheading}s;
  }
`;

const ContainerTop = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  padding: 30px 0;
  max-width: 30em;

  & > * {
    margin: 15px 0;
    text-align: center;
    text-wrap: balance;
  }

  & strong {
    font-weight: 400;
    color: ${theme.colors.helsinkiBlue};
    white-space: nowrap;

    /* Force a line break before the strong element */
    @media (width > 25em) {
      &::before {
        content: '';
        display: block;
      }
    }
  }

  & svg {
    width: 100px;
    height: 100px;
  }
`,
);

const ButtonContainer = styled.div`
  margin: 10px 0;
  max-width: 365px;
  width: 100%;
`;

const getPrepaidInfo = (months: number) =>
  t('membership_signup_prepaid_welcome_moment_paragraph_2', {
    months,
    BlueText: (text) => <strong>{text}</strong>,
  });

export const SignUpPrepaidMoment = () => {
  const { data: offeringData, error, isFetching } = useGetMembershipOfferings();
  const navigate = useNavigate();

  const subscriptionFound = offeringData?.membershipStatus.subscriptionFound;
  const nextPage = subscriptionFound ? routes.complete : routes.address;

  useEffect(() => {
    // If the user has no prepaid months and still lands on this page, redirect them to the next page immediately.
    if (offeringData && offeringData.prepaidMonths < 1) {
      navigate(nextPage);
    }
  }, [offeringData, navigate, nextPage]);

  if (error) {
    throwError('errorOnGettingMembershipOfferings', error);
  }

  // Don't render the component if the user has no prepaid months.
  // This also schedules the animation to start when we know that the user has prepaid months.
  if (isFetching || !offeringData || offeringData.prepaidMonths < 1) {
    return null;
  }

  return (
    <StyledMainContainer>
      <ContainerTop>
        <LogoWrapper>
          <OuraLogo />
        </LogoWrapper>
        <HeaderText data-animate="heading" $fontSize="xxxlarge" $lineHeight="120%">
          {t('membership_signup_prepaid_welcome_moment_paragraph_1')}
        </HeaderText>
        <HeaderText data-animate="subheading" as="h2" $fontSize="xxxlarge" $lineHeight="120%">
          {getPrepaidInfo(offeringData.prepaidMonths)}
        </HeaderText>
      </ContainerTop>
      <ButtonContainer>
        <ContinueButton data-animate="button" onClick={() => navigate(nextPage)}>
          {t('membership_signup_button_next')}
        </ContinueButton>
      </ButtonContainer>
    </StyledMainContainer>
  );
};
