import { gql } from 'graphql-request';

import { axiosQuery } from '../utils/axiosQuery';
import { ApiPaymentGatewayFailedResponse } from '../utils/SupaError';

// Hidden since not used, and we would need to figure out which id to return in case of SUB_EXISTS
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface ApiCreateSubscriptionSuccess {
  __typename?: 'ApiCreateSubscriptionSuccess';
  accountId?: string;
  subscriptionId?: string;
}

export type CreateEntitlementSubscriptionResponse = ApiCreateSubscriptionSuccess | ApiPaymentGatewayFailedResponse;

const CREATE_ENTITLEMENT_SUBSCRIPTION_MUTATION_V2 = gql`
  mutation CreateEntitlementSubscriptionV2 {
    createEntitlementSubscriptionV2 {
      ... on ApiCreateSubscriptionSuccess {
        __typename
        accountId
        subscriptionId
      }
      ... on ApiPaymentGatewayFailedResponse {
        __typename
        errorGroup
        isRetryable
        message
      }
    }
  }
`;

export const createEntitlementSubscription = () =>
  axiosQuery<CreateEntitlementSubscriptionResponse>(
    'CreateEntitlementSubscriptionV2',
    CREATE_ENTITLEMENT_SUBSCRIPTION_MUTATION_V2,
  );
