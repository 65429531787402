import { createSubscription } from 'apps-common/graphql/createSubscription';
import { PaymentMethodType } from 'apps-common/types';
import { convertPaymentMethodTypeToTrackingType, track } from 'apps-common/utils/analytics';
import { isStrictNever } from 'apps-common/utils/isStrictNever';
import { logger } from 'apps-common/utils/logger';
import { isGatewayError } from 'apps-common/utils/SupaError';
import { isSupaGQLError } from 'apps-common/utils/SupaGraphQLError';
import { getTranslationLanguage } from 'translations';

import { routes } from '../routes';
import { useStore } from '../store';

export const onCallbackError = (type: PaymentMethodType) => (error: Error) => {
  const setPaymentError = useStore.getState().setPaymentError;
  setPaymentError(error);

  // This is most likely caused by the user clicking the back button after
  // successfully creating a subscription.
  const routeToCompletePageErrors = ['SUBSCRIPTION_ALREADY_EXISTS', 'RESOURCE_LOCK_ALREADY_ACQUIRED'];
  if (isSupaGQLError(error) && routeToCompletePageErrors.includes(error.code)) {
    window.parent.location.replace(routes.complete);
    return;
  }

  track({
    event: 'Adding Payment Method Failed',
    payload: {
      paymentMethodType: convertPaymentMethodTypeToTrackingType(type),
      reason: error.message ?? 'Unknown error',
    },
  });

  logger.error('Error creating subscription', { error });
  window.parent.location.replace(routes.paymentMethod);
};

const logGatewayError = (type: PaymentMethodType) => (error: unknown) => {
  if ((isSupaGQLError(error) && error.code === 'PAYMENT_GATEWAY') || isGatewayError(error)) {
    const { message, code } = error;
    logger.error(`GatewayError in ${type} callback`, {
      message,
      code,
    });
  }

  throw error;
};

export const onCallbackSuccess = (type: PaymentMethodType) => {
  const supportedTranslations: string[] = JSON.parse(import.meta.env.VITE_TRANSLATIONS_SUPPORTED || '[]');

  let getGateway: () => string;
  switch (type) {
    case PaymentMethodType.CreditCard: {
      getGateway = () => useStore.getState().gateways.creditCardGatewayName;
      break;
    }
    case PaymentMethodType.PayPal: {
      getGateway = () => useStore.getState().gateways.paypalGatewayName;
      break;
    }
    default: {
      isStrictNever(type);
    }
  }

  const gatewayErrorLogHandler = logGatewayError(type);
  const criticalErrorHandler = onCallbackError(type);

  return async (paymentMethodId: string) => {
    const email = useStore.getState().signupInfo.email;
    const { currency, billingPeriod } = useStore.getState().selectedRatePlan!;
    const userAddressForm = useStore.getState().userAddressForm!;
    const gatewayName = getGateway();
    const locale = getTranslationLanguage(supportedTranslations);

    logger.debug(`${type} callback create subscription`, { paymentMethodId });
    try {
      await createSubscription(paymentMethodId, userAddressForm, gatewayName, currency, billingPeriod, email).catch(
        gatewayErrorLogHandler,
      );
    } catch (error) {
      return criticalErrorHandler(error as Error);
    }

    const trackingData = {
      paymentMethodType: convertPaymentMethodTypeToTrackingType(type),
      currency,
      locale,
      billingPeriod,
      paymentGateway: gatewayName,
    };

    logger.debug(`${type} callback subscription created successfully`, {
      trackingData,
    });
    track({
      event: 'Subscription Created',
      payload: {
        cta: 'save_exit / checkout',
        action: 'onboarding_complete',
        ...trackingData,
      },
    });

    logger.info(`${type} callback success, redirecting to complete page`);
    window.parent.location.replace(routes.complete);
  };
};
