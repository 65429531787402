import { styled } from 'styled-components';

import { t } from 'translations';
import { Link, Paragraph } from 'ui/styles/text';
import { media } from 'ui/themes/signup';

import { track } from '../utils/analytics';
const StyledP = styled(Paragraph)`
  padding: 24px 20px 0;
  text-align: justify;
  ${media.large`
  padding: 24px 0 0;
`}
`;

export const getPlanSelectHelpLink = (prepaidMonths: number) => (
  <StyledP $fontSize="small" $lineHeight="140%" $color="grayLight">
    {t('membership_signup_planselection_help', {
      LinkToSupportPage: (linkText) => (
        <Link
          href="https://support.ouraring.com/hc/en-us/articles/34172788742035-Activating-a-Prepaid-Oura-Membership"
          $decoration="underline"
          $fontSize="14px"
          target="_blank"
          onClick={() => {
            track({
              event: 'Link Clicked',
              payload: {
                cta: 'signup_planselection_help',
                location: 'body',
                prepaidMonths,
              },
            });
          }}
        >
          {linkText}
        </Link>
      ),
    })}
  </StyledP>
);
export const PlanSelectHelp = (prepaidMonths: number) => getPlanSelectHelpLink(prepaidMonths);
