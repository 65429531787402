import { useSearchParams } from 'react-router';

import { useAuth } from 'apps-common/context/auth';
import { useCompleteLogin, useInitLogin } from 'apps-common/hooks/AccountApi';
import { identify, setUserId } from 'apps-common/utils/analytics';
import { throwError } from 'apps-common/utils/errorHandler';
import { logger } from 'apps-common/utils/logger';

import { getMoiSearchParams } from '../utils/authentication';

export const useMoiLogin = (setAccountId: (accountId: string) => void) => {
  const { login } = useAuth();
  const [searchParams] = useSearchParams();
  const { error, errorDescription, redirectUri, state, code, nonce } = getMoiSearchParams(searchParams);

  logger.info('Moi login started');

  if (error) {
    logger.error('Moi login failed', {
      reason: 'Error in login: ' + error + ' : ' + errorDescription,
    });
    throwError('moiLoginError', errorDescription);
  }

  const { data: initData, isFetching } = useInitLogin(redirectUri, !(state && code), nonce);
  const { data: endLoginData, isFetching: isFetchingLoginEnd } = useCompleteLogin(
    redirectUri,
    code,
    state,
    state !== '' && code !== '' && !isFetching,
  );

  if (isFetching || isFetchingLoginEnd) {
    return false;
  }

  if (initData?.authorizationRequestUrl) {
    window.location.href = initData.authorizationRequestUrl;
  }

  if (endLoginData) {
    setUserId(endLoginData.analyticsId);
    setAccountId(endLoginData.accountId);
    identify({ traits: { isHipaa: endLoginData.isHipaa } });
    login();
    logger.info('Moi login completed');
    return true;
  }

  return false;
};
