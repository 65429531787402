import { useGetProducts } from 'apps-common/hooks/useGetProducts';
import { Currency } from 'apps-common/types';
import { getRatePlansFromProducts } from 'apps-common/utils/getProduct';

export const useGetProductRatePlans = (country?: string, currency?: Currency) => {
  const { data, ...rest } = useGetProducts(country, currency);

  const ratePlans = data?.products && getRatePlansFromProducts(data.products);

  return { ratePlans, ...rest };
};
