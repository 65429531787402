export const routes = {
  index: '/',
  prepaidMoment: '/prepaid',
  address: '/address',
  product: '/product',
  paymentMethod: '/payment-method',
  zuoraCallback: '/payment-method/card-callback',
  paypalCallback: '/payment-method/paypal-callback',
  complete: '/setup-complete',
  login: '/login',
} as const;
