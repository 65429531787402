import { useStatsigClient, useExperiment as useStatsigExperiment } from '@statsig/react-bindings';

export enum Experiments {
  ANNUAL_FIRST = 'annual_vs_monthly',
  PREPAID_PLUS = 'prepaidplus',
}

export const useExperiment = (key: Experiments) => {
  const { logEvent } = useStatsigClient();
  return { experiment: useStatsigExperiment(key), logEvent };
};
