import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { useMutationState } from '@tanstack/react-query';

import { useGetMembershipOfferings } from 'apps-common/hooks/useGetMembershipOfferings';
import { Experiments, useExperiment } from 'apps-common/utils/experiments';
import { isEntitlementUser, isGen2User, isPayingUser } from 'apps-common/utils/helpers';

import { routes } from '../routes';

export const useHandleNextPage = (ready: boolean) => {
  const navigate = useNavigate();
  const { data: offeringData } = useGetMembershipOfferings(false);
  const prepaidPlusExperiment = useExperiment(Experiments.PREPAID_PLUS).experiment.get('prepaidPlus');

  const createPartnershipSubscriptionMutationState = useMutationState({
    filters: { mutationKey: ['createPartnershipSubscription'] },
  });
  const createEntitlementSubscriptionMutationState = useMutationState({
    filters: { mutationKey: ['createEntitlementSubscription'] },
  });

  useEffect(() => {
    if (!ready || !offeringData) return;

    const {
      membershipStatus: { type, subscriptionFound },
      eligibleForPartnership,
      prepaidMonths,
    } = offeringData;

    if (eligibleForPartnership) {
      if (
        createPartnershipSubscriptionMutationState.length > 0 &&
        createPartnershipSubscriptionMutationState[0].status === 'success'
      ) {
        navigate(routes.complete);
      }
      return; // isPayingUser() will return true for partnership users
    }

    if (isEntitlementUser(type)) {
      if (
        createEntitlementSubscriptionMutationState.length > 0 &&
        createEntitlementSubscriptionMutationState[0].status === 'success'
      ) {
        navigate(routes.complete);
      }
      return;
    }

    if (isPayingUser(type)) {
      if (prepaidMonths > 0 && prepaidPlusExperiment) {
        navigate(routes.prepaidMoment);
      } else {
        navigate(subscriptionFound ? routes.complete : routes.address);
      }
    }

    if (isGen2User(type)) {
      // Gen2 users don't have subscriptions. Always to complete page after creating an account
      navigate(routes.complete);
    }
  }, [navigate, offeringData, ready]);
};
