import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useMutationState } from '@tanstack/react-query';
import { SignupRequestBodyAcceptHealthDataProcessingEnum } from 'oura-account-api-client';
import { styled } from 'styled-components';

import { useAuth } from 'apps-common/context/auth';
import { useGetAccountInfo } from 'apps-common/hooks/AccountApi';
import { useGetMembershipOfferings } from 'apps-common/hooks/useGetMembershipOfferings';
import { useMoiLogin } from 'apps-common/hooks/useMoiLogin';
import { resetAllSlices } from 'apps-common/store';
import { SignupMembershipType } from 'apps-common/types';
import { identify, setUserId, track } from 'apps-common/utils/analytics';
import { throwError } from 'apps-common/utils/errorHandler';
import { t } from 'translations';
import { Button, CTALoader, EmailField, Form, Header, Loader } from 'ui';
import { MainContainer } from 'ui/styles/containers';
import { SmallText } from 'ui/styles/text';
import { media } from 'ui/themes/signup';

import { useCreateSubscription } from '../hooks/useCreateSubscription';
import { useHandleNextPage } from '../hooks/useHandleNextPage';
import { useStore } from '../store';
const LoginForm = styled(Form)`
  & > button:last-child {
    align-self: center;
  }

  ${media.xlarge`
      & > button:last-child {
        align-self: flex-end;
      }
    `}
`;

const StartButton = styled(Button)`
  min-width: 365px;
`;

export interface FormData {
  email: string;
  password: string;
  userAgreement: SignupRequestBodyAcceptHealthDataProcessingEnum;
  marketingAgreement: boolean;
}

const useFetchOfferingData = (userCreated: boolean) => {
  const { loggedIn } = useAuth();
  const { error: errorOnOfferrings, refetch: refetchMembershipOfferings } = useGetMembershipOfferings(false);

  useEffect(() => {
    if (loggedIn && userCreated) void refetchMembershipOfferings();
  }, [userCreated, loggedIn, refetchMembershipOfferings]);

  useEffect(() => {
    if (errorOnOfferrings && userCreated) {
      throwError('errorOnGettingMembershipOfferings', errorOnOfferrings);
    }
  }, [errorOnOfferrings, userCreated]);
};

const useTrackCustomerType = (userCreated: boolean) => {
  const setUserType = useStore((state) => state.setUserType);
  const { data: membershipOfferings } = useGetMembershipOfferings(false);

  useEffect(() => {
    if (membershipOfferings && userCreated) {
      const {
        membershipStatus: { type: userType, subscriptionFound },
        prepaidMonths,
        eligibleForPartnership,
      } = membershipOfferings;

      setUserType(userType);

      if (eligibleForPartnership) {
        track({
          event: 'Partnership Customer Found',
          payload: {
            existingSubscription: subscriptionFound,
            prepaidMonths,
          },
        });
        return;
      }

      switch (userType) {
        case SignupMembershipType.PAYING: {
          track({
            event: 'Paying Customer Found',
            payload: {
              existingSubscription: subscriptionFound,
              prepaidMonths,
            },
          });
          break;
        }

        case SignupMembershipType.ENTITLEMENT: {
          track({
            event: 'Entitlement Customer Found',
            payload: {
              existingSubscription: subscriptionFound,
              prepaidMonths,
            },
          });
          break;
        }

        case SignupMembershipType.LEGACY: {
          track({
            event: 'Legacy Customer Found',
            payload: {
              existingSubscription: subscriptionFound,
              prepaidMonths,
            },
          });
          break;
        }
      }
    }
  }, [membershipOfferings, setUserType, userCreated]);
};
const useTrackIdentity = (email: string) => {
  useEffect(() => {
    if (email) {
      identify({ traits: { email } });
    }
  }, [email]);
};

export const LoginPage = () => {
  const pendingMutations = useMutationState({
    filters: { status: 'pending' },
  });
  const [nextButtonReady, setNextButtonReady] = useState(false);
  useEffect(() => {
    // reset zustand store on start page to avoid stale data
    resetAllSlices();
  }, []);

  const setAccountId = useStore((state) => state.setAccountId);
  const setEmail = useStore((state) => state.setEmail);
  const setSignupInfo = useStore((state) => state.setSignupInfo);

  const { isFetching: isFetchingOffering } = useGetMembershipOfferings(false);

  const { register, handleSubmit, setValue } = useForm<FormData>({
    criteriaMode: 'all',
    mode: 'onTouched',
  });

  const moiLoginFinished = useMoiLogin(setAccountId);
  useFetchOfferingData(moiLoginFinished);
  useTrackCustomerType(moiLoginFinished);
  useCreateSubscription(moiLoginFinished);
  const { data } = useGetAccountInfo(moiLoginFinished);

  useTrackIdentity(useStore((state) => state.email));
  useHandleNextPage(nextButtonReady && moiLoginFinished);

  useEffect(() => {
    if (data) {
      setValue('email', data.email ?? '');
      setAccountId(data.accountId);
      setEmail(data.email ?? '');
      setUserId(data.analyticsId);
      //For compatibility with old login set signupinfo here
      setSignupInfo({
        email: data.email ?? '',
        token: '',
        termsOfServiceVersion: '',
        privacyPolicyVersion: '',
        ringSerialStatus: data.ringSerialStatus,
        accountStatus: 'Ready', //Safe to assume that the account is ready after curity
      });
    }
  }, [data]);

  if (isFetchingOffering || !data) {
    return <Loader />;
  }

  const onSubmit = handleSubmit(() => {
    track({
      event: 'CTA Clicked',
      payload: { cta: 'next', action: 'onboarding_next_step' },
    });
    setNextButtonReady(true);
  });

  const isButtonDisabled = isFetchingOffering || pendingMutations.length > 0;

  const title = t('membership_hub_membership_setup');

  return (
    <>
      <Header appType="signup" pageType="landing" title={title} testId="signup-welcome-header"></Header>
      <MainContainer>
        <LoginForm onSubmit={onSubmit}>
          <EmailField disabled id="email" {...register('email')} />
          <SmallText $fontSize="xsmall" $margin="10px">
            {t('membership_hub_first_month_on_us')}
          </SmallText>
          <StartButton data-testid="get-started-submit-button">
            {isButtonDisabled ? <CTALoader /> : t('membership_signup_welcome_page_button_getstarted')}
          </StartButton>
        </LoginForm>
      </MainContainer>
    </>
  );
};
