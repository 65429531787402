import { useEffect } from 'react';

import { styled } from 'styled-components';

import { useGetAccount } from 'apps-common/hooks/useGetAccount';
import { Experiments, useExperiment } from 'apps-common/utils/experiments';
import { isEntitlementUser, isGen2User, isPayingUser } from 'apps-common/utils/helpers';
import { logger } from 'apps-common/utils/logger';
import { isMobileDevice } from 'apps-common/utils/userAgent';
import { t } from 'translations';
import { Button, Header, Loader } from 'ui';

import { useGetPrepaidInfo } from '../hooks/useGetPrepaidInfo';
import { useTrackFunnelCompleted } from '../hooks/useTrackFunnelCompleted';
import { useStore } from '../store';
import { ConfirmationPage } from './ConfirmationPage';

const BackToAppButton = styled(Button)`
  min-width: 365px;
`;

const CompletePage = () => {
  const { data, error, isFetching } = useGetAccount();
  const { prepaidMonths, isFetching: isFetchingPrepaidInfo } = useGetPrepaidInfo();
  const userType = useStore((state) => state.userType);
  const signupInfo = useStore((state) => state.signupInfo);

  useTrackFunnelCompleted(prepaidMonths, isFetchingPrepaidInfo);

  useEffect(() => {
    if (error) {
      logger.warn(`Error in fetching account details`, { error });
    }
  }, [error]);

  if (isFetching || isFetchingPrepaidInfo) {
    return <Loader />;
  }

  const isGen2 = isGen2User(userType);

  const FALLBACK_NAME = t('membership_signup_fallback_name');
  const nameToShow = isPayingUser(userType)
    ? (data?.account?.shipToContact?.firstName ?? FALLBACK_NAME)
    : FALLBACK_NAME;

  const TITLE = t('membership_signup_account_created_title', {
    name: nameToShow,
  });

  const SUB_TITLE = isGen2
    ? t('membership_signup_account_created_success_message_gen2')
    : t('membership_signup_account_created_success_message');
  return (
    <>
      <Header
        appType="signup"
        pageType="complete"
        title={TITLE}
        subTitle={SUB_TITLE}
        testId="signup-complete-header"
        ringSerialStatus={signupInfo.ringSerialStatus}
      />
      {isMobileDevice() && (
        <BackToAppButton onClick={() => window.open('oura://v1/route/home')} data-testid="back-to-app-button">
          {t('membership_signup_setup_complete_back_to_app_button')}
        </BackToAppButton>
      )}
    </>
  );
};

export const SignUpComplete = () => {
  const prepaidPlusExperiment = useExperiment(Experiments.PREPAID_PLUS).experiment.get('prepaidPlus');

  const userType = useStore((state) => state.userType);

  const requiresOldCompletePage = isGen2User(userType) || isEntitlementUser(userType);

  if (!requiresOldCompletePage && prepaidPlusExperiment) {
    return <ConfirmationPage />;
  }

  return <CompletePage />;
};
