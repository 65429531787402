import { createBrowserRouter, Outlet, RouterProvider } from 'react-router';

import * as Sentry from '@sentry/react';

import { ErrorPage } from 'apps-common/components/ErrorPage';
import { CreditCardCallbackPage, PaypalCallbackPage } from 'apps-common/components/PaymentCallbackPage';
import { PaymentMethodType } from 'apps-common/types';
import { track } from 'apps-common/utils/analytics';
import { BasePage, Footer } from 'ui';
import { Container } from 'ui/styles/containers';

import { LoginPage } from './pages/LoginPage';
import { ProductPage } from './pages/ProductPage';
import { SignUpAddress } from './pages/SignUpAddressPage';
import { SignUpComplete } from './pages/SignupCompletePage';
import { SignUpPaymentMethod } from './pages/SignUpPaymentMethodPage';
import { SignUpPrepaidMoment } from './pages/SignUpPrepaidMoment';
import { SignUpWelcome } from './pages/SignUpWelcome';
import { routes } from './routes';
import { useStore } from './store';
import { onCallbackError, onCallbackSuccess } from './utils/paymentMethodCallback';
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

const Root = () => (
  <Container>
    <BasePage>
      <Outlet />
      <Footer
        onLinkClick={({ name }) => {
          track({
            event: 'Link Clicked',
            payload: { cta: name, location: 'footer' },
          });
        }}
        showLogout={false}
      />
    </BasePage>
  </Container>
);

const router = sentryCreateBrowserRouter([
  {
    path: routes.index,
    element: <Root />,
    children: [
      {
        errorElement: <ErrorPage appType="signup" />,
        children: [
          {
            index: true,
            element: <SignUpWelcome />,
          },
          {
            path: routes.prepaidMoment,
            element: <SignUpPrepaidMoment />,
          },
          {
            path: routes.address,
            element: <SignUpAddress />,
          },
          {
            path: routes.product,
            element: <ProductPage />,
          },
          {
            path: routes.paymentMethod,
            element: <SignUpPaymentMethod />,
          },
          {
            path: routes.paypalCallback,
            element: (
              <PaypalCallbackPage
                onSuccess={onCallbackSuccess(PaymentMethodType.PayPal)}
                onError={onCallbackError(PaymentMethodType.PayPal)}
                isUnitedStates={useStore.getState().userAddressForm?.shippingAddress.country === 'US'}
                paymentGateway={useStore.getState().gateways.paypalGatewayName}
              />
            ),
          },
          {
            path: routes.complete,
            element: <SignUpComplete />,
          },
          {
            path: routes.login,
            element: <LoginPage />,
          },
          {
            path: '*',
            element: <ErrorPage errorPageType={'notFound'} appType="signup" />,
          },
        ],
      },
    ],
  },
  {
    path: routes.zuoraCallback, // Don't add as a children of the main route, as it's rendered inside an iframe
    element: (
      <CreditCardCallbackPage
        onSuccess={onCallbackSuccess(PaymentMethodType.CreditCard)}
        onError={onCallbackError(PaymentMethodType.CreditCard)}
      />
    ),
  },
]);

export const Router = () => <RouterProvider router={router} />;
