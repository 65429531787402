import { useEffect } from 'react';
import { FormattedDate } from 'react-intl';
import { useNavigate } from 'react-router';

import { PaymentMethodForm } from 'apps-common/components/PaymentMethodForm';
import { useGetMembershipOfferings } from 'apps-common/hooks/useGetMembershipOfferings';
import { useGetPaymentGateways } from 'apps-common/hooks/useGetPaymentGateways';
import { Currency, FlowType } from 'apps-common/types';
import { track } from 'apps-common/utils/analytics';
import { throwError } from 'apps-common/utils/errorHandler';
import { Experiments, useExperiment } from 'apps-common/utils/experiments';
import { logger } from 'apps-common/utils/logger';
import { renderPaymentErrorMessage } from 'apps-common/utils/paymentError';
import { onPaypalStart } from 'apps-common/utils/paypal';
import { t } from 'translations';
import { ErrorBanner, Header, Loader } from 'ui';
import { MainContainer } from 'ui/styles/containers';

import { useGetPrepaidInfo } from '../hooks/useGetPrepaidInfo';
import { routes } from '../routes';
import { useStore } from '../store';
import { getSignupBannerText } from '../utils/helpers';

const APP_URL = import.meta.env.VITE_URL;
const PAYPAL_COMPONENT_ID = 'paypal-button';
const PAYPAL_MERCHANT_ID_US = import.meta.env.VITE_PAYPAL_MERCHANT_ID_US;
const PAYPAL_MERCHANT_ID_ROW = import.meta.env.VITE_PAYPAL_MERCHANT_ID_ROW;

const usePrepoulateAddress = () => {
  const billingAddress = useStore((state) => state.userAddressForm?.billingAddress);
  const isoAlpha3Code = useStore((state) => state.billingCountryIsoAlpha3Code);

  return {
    creditCardCountry: isoAlpha3Code,
    creditCardState: billingAddress?.state,
    creditCardAddress1: billingAddress?.address1,
    creditCardAddress2: billingAddress?.address2,
    creditCardCity: billingAddress?.city,
    creditCardPostalCode: billingAddress?.postalCode,
  };
};

const useSaveGatewaysToStore = (currency: Currency) => {
  const country = useStore((state) => state.userAddressForm?.shippingAddress.country)!;
  const setGateways = useStore((state) => state.setGateways);

  const { data: gatewayData, error } = useGetPaymentGateways(country, currency);
  useEffect(() => {
    if (gatewayData) {
      setGateways(gatewayData);
    }
  }, [gatewayData, setGateways]);

  if (error) {
    logger.error('Fetching payment gateways failed', {
      country,
      currency,
      gatewayData,
      error,
    });
    throwError('paymentGatewayError', error);
  }
};

export const SignUpPaymentMethod = () => {
  const navigate = useNavigate();

  const userAddressForm = useStore((state) => state.userAddressForm!);
  const signupInfo = useStore((state) => state.signupInfo);
  const ratePlan = useStore((state) => state.selectedRatePlan);
  const clearPaymentError = useStore((state) => state.clearPaymentError);
  const setPaymentError = useStore((state) => state.setPaymentError);
  const paymentError = useStore((state) => state.paymentError);
  const {
    hostedPageId,
    creditCardGatewayName: ccGatewayName,
    paypalGatewayName: paypalGatewayName,
  } = useStore((state) => state.gateways);

  if (!ratePlan) {
    throwError('noProductFound', `product not found for country: ${userAddressForm.shippingAddress?.country}`);
  }

  const prepaidPlusExperiment = useExperiment(Experiments.PREPAID_PLUS).experiment.get('prepaidPlus');

  const { isFetching: isFetchingOfferings } = useGetMembershipOfferings();

  const populatedFields = usePrepoulateAddress();

  const { currency } = ratePlan;
  useSaveGatewaysToStore(currency as Currency);

  let TITLE: JSX.Element | undefined;
  let SUBTITLE: JSX.Element | undefined;

  const { billingStartDate, isPrepaid, prepaidMonths } = useGetPrepaidInfo();

  if (prepaidPlusExperiment) {
    TITLE = t('membership_hub_signup_payment_method_page_title');
    SUBTITLE = isPrepaid
      ? t('membership_hub_signup_subtitle_prepaid', {
          prepaid_validto: <FormattedDate value={billingStartDate} format="default" />,
        })
      : t('membership_hub_signup_subtitle');
  } else {
    TITLE = t('membership_signup_payment_method_title');
    SUBTITLE = isPrepaid ? t('signup_payment_page_prepaid_subtitle') : t('signup_payment_page_default_subtitle');
  }

  const BANNER_TEXT = getSignupBannerText(isPrepaid, prepaidMonths);

  const isUnitedStates = userAddressForm.shippingAddress?.country === 'US';
  const returnUrl = new URL(routes.paypalCallback, APP_URL).href;
  const cancelUrl = new URL(routes.paymentMethod, APP_URL).href;
  const paypalMerchantId: string = isUnitedStates ? PAYPAL_MERCHANT_ID_US : PAYPAL_MERCHANT_ID_ROW;

  const header = (
    <Header
      appType="signup"
      pageType="create"
      title={TITLE}
      subTitle={isFetchingOfferings ? undefined : SUBTITLE}
      bannerText={prepaidPlusExperiment ? undefined : BANNER_TEXT}
      onBackClick={() => navigate(routes.product)}
      steps={prepaidPlusExperiment ? undefined : { current: 3, total: 3 }}
      testId="signup-payment-method-header"
      ringSerialStatus={signupInfo.ringSerialStatus}
    />
  );

  if (isFetchingOfferings) {
    return (
      <>
        {header}
        <MainContainer>
          <Loader />
        </MainContainer>
      </>
    );
  }

  return (
    <>
      {header}
      <MainContainer>
        {paymentError && <ErrorBanner marginBottom="15px">{renderPaymentErrorMessage(paymentError)}</ErrorBanner>}
        <PaymentMethodForm
          flowType={FlowType.normal}
          hsaEligible={isUnitedStates}
          creditCard={{
            pageId: hostedPageId,
            gatewayName: ccGatewayName,
            prepopulatedFields: populatedFields,
            onSubmit: () => {
              clearPaymentError();
              track({
                event: 'CTA Clicked',
                payload: { cta: 'save_exit', action: 'onboarding_next_step' },
              });
            },
            clientErrorCallback: (zClientError) => {
              const { errorCode: code, errorMessage: message, ...rest } = zClientError;
              setPaymentError({ ...rest, message, code: code.toString() });
              logger.warn('CreditCardForm client error', {
                error: zClientError,
              });
            },
          }}
          paypal={{
            gatewayName: paypalGatewayName,
            componentId: PAYPAL_COMPONENT_ID,
            merchantId: paypalMerchantId,
            onSubmit: () => {
              clearPaymentError();
              void onPaypalStart({
                returnUrl,
                cancelUrl,
                isUnitedStates,
                currency,
              });
            },
          }}
        />
      </MainContainer>
    </>
  );
};
